@keyframes astronaut{
  0%{
      transform: rotate(xx) translateX(0%) translateY(0%);
      }
  20%{
      transform: translateX(-5%) translateY(-10%);
      }
  60%{
      transform: translateX(-25%) translateY(-15%);
      }
  100%{
      transform: rotate(xx) translateX(0%) translateY(0%);
      }
}

@keyframes planet {
  0% {
    transform: rotate(0) translateX(0%);
  }
  10% {
    transform: rotate(-5deg) translateX(5%) translateY(-10%);
  }
  20% {
    transform: rotate(5deg) translateX(12%) translateY(-6%);
  }
  50% {
    transform: rotate(15deg) translateX(3%) translateY(5%);
  }
  70% {
    transform: rotate(3deg) translateX(9%) translateY(12%);
  }
  85% {
    transform: rotate(12deg) scale(0.9) translateX(12%) translateY(-22%);
  }
  100% {
    transform: rotate(0);
  }
}
